<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：产品进出v3</strong>
                                    <el-tag type="danger" style="margin-left: 20px">期初支数：{{periodData.before_count}}</el-tag>
                                    <el-tag type="success" style="margin-left: 20px">期初库存成本金额：{{$XEUtils.commafy(periodData.before_stock_amount, { digits: 2})}}</el-tag>
                                    <el-tag type="success" style="margin-left: 20px">期初库存支价：{{$XEUtils.commafy(periodData.before_single_price, { digits: 2})}}</el-tag>
                                    <el-tag type="warning" style="margin-left: 20px">本期每支销售成本(月末一次加权平均)：{{$XEUtils.commafy(periodData.kd_cost, { digits: 2})}}</el-tag>
                                    <el-tag type="warning" style="margin-left: 20px">上一次进仓成本支价：{{$XEUtils.commafy(periodData.naked_into_warehouse_cost_single_price, { digits: 2})}}</el-tag>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-date-picker
                                        v-model="pickerDateMonth"
                                        style=" margin-top: 8px;padding-top: 2px; width: 180px"
                                        type="month"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerDateEvent(),getIntoAndOut()"
                                        :clearable="false"
                                        value-format="yyyy-MM-dd"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份">
                                </el-date-picker>
                                <el-date-picker
                                        v-show="false"
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <!--<el-col :lg="4" :sm="12" :md="4">
                                <el-form-item label="往来单位:">
                                    <el-select ref="draw_company" v-model="formData.company_name" size="mini"
                                               default-first-option no-data-text="没有搜索结果!" placeholder="请选择(必填)"
                                               clearable filterable remote
                                               :remote-method="$_searchCustomerAndSupplier">
                                        <el-option v-for="(item,key) in $data._CustomerAndSupplierList" :key="key"
                                                   :label="item.draw_company" :value="item.draw_company">
                                            <span style="margin-right:8px;">{{ item.draw_company}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>-->
                            <el-col :lg="3" :sm="12" :md="4">
                                <el-form-item label="类别:">
                                    <el-select v-model="formData.category" size="mini" no-data-text="没有搜索结果!" placeholder="请选择(必填)" clearable  filterable>
                                        <el-option v-for="item in this.$store.getters.getCategoryList" :key="item.id" :label="item.name" :value="item.name">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="4">
                                <el-form-item label="规格:">
                                    <el-select v-model="formData.specification" size="mini" no-data-text="没有搜索结果!" placeholder="请选择(必填)"
                                               default-first-option remote
                                               :remote-method="(query) => $_searchWarehouseStock(query,'',true)" clearable filterable >
                                        <el-option v-for="item in this.$store.getters.getSpecificationList" :key="item.id" :label="item.specification_name" :value="item.specification_name">
                                            <span style="margin:15px;">{{ item.specification_name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="4">
                                <el-form-item label="仓库:">
                                    <el-select v-model="formData.warehouse_name" no-data-text="没有搜索结果!"
                                               size="mini"
                                               placeholder="全部" default-first-option
                                               @change="formData.warehouse_name = $event.name,formData.warehouse = $event.id"
                                               @clear="$_searcWarehouseList"
                                               @focus="$_searcWarehouseList" :filter-method="$_warehouseListFilter"
                                               clearable filterable>
                                        <el-option v-for="item in $store.getters.getWarehouseList" :key="item.id" :label="item.name"
                                                   :value="item">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="getIntoAndOut"
                                           size="mini">搜索
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openBill($refs.xGrid.getCurrentRecord())" size="mini">查询
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                show-footer
                                :footer-method="footerMethod"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx'],isFooter:true,isMerge:true}"
                                keep-source
                                ref="xGrid"
                                size="mini"
                                :height="(this.getViewHeight() - 150)+''"
                                highlight-hover-row
                                highlight-current-row
                                :row-class-name="tableRowClassName"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">

                        </vxe-grid>
                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>



    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                type:'costAccounting',/*单据类型 产品进出*/
                filterData: {},
                periodData:{
                    before_count: 0,//期初支数
                    before_stock_amount: 0,//期初库存金额
                    before_single_price: 0,//期初库存支价
                    naked_price_sum: 0,/*销售开单 产品裸价合计*/
                    single_count_sum: 0,
                    amount_of_product_sum: 0,
                    kd_cost: 0,
                    naked_price_money_single: 0,/* 产品裸价 单价 * 支数  =  产品裸价 期初 */
                    naked_price_money: 0,
                    naked_into_warehouse_cost_single_price: 0,//成本支价
                    naked_into_warehouse_cost_sum: 0
               },
                formData: {
                    company_name :'',
                    company_uuid :'',
                    category:'',
                    warehouse:'',
                    warehouse_name:'',
                    specification:'',
               },
                warehouseList: [],
                category: [],
                pickerDate: [startDate,new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                pickerDateMonth: new Date(),
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'bill_stock_type', width: 80, title: '类型'
                   }, {
                        field:'bill_stock_date', width: 100, title: '日期',
                        formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'company_name', width: 150, title: '往来单位'
                   },
                    {
                        field:'warehouse', width: 50, title: '仓库'
                   },
                    {
                        field:'bill_id_stock', width: 160, title: '单号'
                    },
                    {
                        field:'specification', width: 130, title: '规格'
                   }, {
                        field:'category', width: 60, title: '类别'
                   },{
                        field:'single_count',visible: false, width: 140, title: '本次进出支数'
                   }, {
                        field:'singlein',visible: true, width: 80, title: '进支数'
                   }, {
                        field:'in_money',visible: true, width: 80, title: '进成本价',
                        titleHelp: {message: '读取进仓单的成本=产品裸价+外省运费'},
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                        },
                    },{
                        field:'singleout',visible: true, width: 80, title: '出支数'
                    }, {
                        field:'out_money',visible: true, width: 80, title: '出成本金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                        },
                    }, {
                        field:'balance_cnt', width: 80, title: '结存支数'
                   }, {
                        field:'kd_cost', width: 110, title: '本期每支销售成本'
                   },{
                        field:'balance_price_single', width: 100, title: '结存成本支价', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                        },
                   },{
                        field:'balance_money', width: 100, title: '结存成本金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', { digits: 2})
                        },
                    },
                ],
                tableDataMain: [],
                sortData: {},
           }
       },
        methods: {
            openBill(row){
                let obj = '';
                if (row.stock_type == 'KD'){
                    obj = {path: '/transferSlip', query: {bill_id_kd : row.bill_id_stock}}
                }else if((row.stock_type == 'HC')){
                    obj = {path: '/replaceOutWarehouse', query: {bill_id: row.bill_id_stock}}
                }else if (row.stock_type == 'JC' || row.stock_type == 'JG-JC' || row.stock_type == 'JG-CC'){
                    obj =  {path: '/intoTheWarehouse', query: {bill_id: row.bill_id_stock }}
                }else if(row.type == 'QC'){
                    this.$message({
                        showClose: true,
                        message: '期初数，请到账套初始化中查看！',
                        type: 'error'
                    });
                    return
                }
                this.$_openType(obj);
            },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'singlein' || column.property == 'singleout' || column.property == 'in_money' || column.property == 'out_money') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       }else if(column.property == 'balance_cnt' || column.property == 'balance_money'){
                            //放最后一个结存数
                            if (data[data.length-1]!= null){
                                sums.push(this.$XEUtils.commafy(data[data.length-1][column.property], {digits: 2}))
                            }
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            getIntoAndOut() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/goodsInOut/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    loading.close();
                    console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableDataMain = []
                        this.tableDataMain = response.data.page
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                        this.periodData = response.data.periodData
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getIntoAndOut();
           },

            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },

       },
        created() {
            if (!this.isBlankVue(this.$route.query.pickerDate)){
                this.pickerDate = this.$route.query.pickerDate
                this.pickerDateMonth = this.pickerDate[0]
                this.formData.category = this.$route.query.category
                this.formData.specification = this.$route.query.specification
                this.formData.warehouse = this.$route.query.warehouse
                this.formData.warehouse_name = this.$route.query.warehouse_name
                this.getIntoAndOut()
            }
            this.$_searcWarehouseList('');
            this.$_searchCategoryList('');
            //this.getIntoAndOut();
            this.loading = true
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
